import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Form, Input, DatePicker, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import AssetInformationBlock from "components/forms/formBlock/asset/AssetInformationBlock";
import settings from "config/config";

import { FINANCING_COMPANY, storeFinancingCompany } from "services/formService";
import Modal from "components/Modal";
import StatusModal from "components/StatusModal";
import CompanySearchModal from "components/commercialProposal/CompanySearchModal";
import PhoneNumber from "components/forms/formElements/PhoneNumber";
import CustomInput from "components/shared/form/Input";
import CustomTextArea from "components/shared/form/TextArea";

// import CustomSelect from "components/shared/form/Select";

import Select from "components/shared/form/CustomSelect";
import moment from "moment/moment";

const CommercialProposalForm = () => {
  const [form] = Form.useForm();
  const [financeCompany, setFinanceCompany] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const imageSrc = "/images/take-pictures-instructions.jpg"; // Load from public folder




  const [fileList, setFileList] = useState([]);



  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [statusModalMessage, setStatusModalMessage] = useState("");

  const [isCompanySearchModalOpen, setIsCompanySearchModalOpen] = useState(false);

  const gridItems = useMemo(() => [
    { image: "/images/Picture1.jpg", text: t("com-explain-photos-front_right") },
    { image: "/images/Picture2.jpg", text: t("com-explain-photos-front_left") },
    { image: "/images/Picture3.jpg", text: t("com-explain-photos-vin_number") },
    { image: "/images/Picture4.jpg", text: t("com-explain-photos-interior") },
    { image: "/images/Picture5.jpg", text: t("com-explain-photos-rear_right") },
    { image: "/images/Picture6.jpg", text: t("com-explain-photos-rear_left") },
    { image: "/images/Picture7.jpg", text: t("com-explain-photos-odometer") },
    { image: "/images/Picture8.jpg", text: t("com-explain-photos-documents") },
  ], [t]); // Recalculate when language changes

  const headerText = t("com-header-text");
  const headerImageSrc = "/images/take-pictures-instructions.jpg";


  const onSearchModalSelect = (company) => {

    if (company.company_code) {



      form.setFieldsValue({


        imonesKodas: company.company_code, // Random company code
        imonesPavadinimas: company.company_name,// Random name
        elPastas: company.email, // Random email
        telNr: company.telephone, // Random phone
        kontaktinisAdresas: company.address,

        // kontaktinisAdresas: "Random Address, Lithuania",
        // atsiskaitomojiSaskaita: "LT" + Math.floor(1000000000000000 + Math.random() * 9000000000000000), // Random IBAN

      });

    }

    toggleSearchModal();
  };


  const toggleSearchModal = () => {
    setIsCompanySearchModalOpen((prev) => !prev);
  };


  const toggleStatusModal = (isSuccess) => {
    setIsStatusModalOpen((prev) => !prev);
    if (isSuccess) {
      window.location.reload(); // Reloads the current page
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const API_HOST = settings.backendApiUrl;


  const handleBeforeUpload = async (file) => {
    return false;
    // You can prevent the default upload behavior by returning false
    // return new Promise((resolve, reject) => {
    //   const formData = new FormData();
    //   formData.append('file', file);

    //   // You can customize the URL and headers for the request
    //   fetch('https://your-api-endpoint.com/upload', {
    //     method: 'POST',
    //     body: formData,
    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       if (data.success) {
    //         resolve();
    //       } else {
    //         reject('Upload failed');
    //       }
    //     })
    //     .catch((error) => reject(error));
    // });
  };

  const onFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();
    //modifying not correct set values before sending to backend
    values.phone = values.applicant.phone;
    delete values.applicant;
    formData.append("data", new Blob([JSON.stringify(values)], { type: "application/json" }));


    // Append uploaded files
    values.images.forEach((file) => {
      formData.append('images[]', file.originFileObj);
    });

    // // Check if images are selected
    // if (values.images && values.images.fileList.length > 0) {
    //   // Append each selected image to formData
    //   values.images.fileList.forEach((file) => {
    //     formData.append("images[]", file.originFileObj); // Use a named array for multiple images
    //   });
    // }

    try {
      const response = await fetch(`${API_HOST}/document-generator/generate-document`, {
        method: "POST",
        body: formData,
      });
      // const response = await fetch(`${API_HOST}/document-generator/generate-document`, {
      //   method: "POST",
      //   body: formData,
      // });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Handle the response
      const blob = await response.text();

      setStatusModalMessage(t("com-explain-status-success"));
      setStatusModal("success");
      toggleStatusModal();
      setLoading(false);
    } catch (error) {
      setStatusModalMessage(t("com-explain-status-error"));
      setStatusModal("Error");
      toggleStatusModal();
      // console.error('Error uploading images:', error);
      setLoading(false);
    }
  };

  const handleUploadChange = async ({ fileList: newFileList }) => {
    const updatedFileList = await Promise.all(
      newFileList.map(async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        return file;
      })
    );

    setFileList(updatedFileList);
    form.setFieldsValue({ images: updatedFileList });
  };

  // Convert file to Base64 for preview
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };


  // const handleUploadChange = (info) => {
  //   // setFileList(fileList);
  //   console.log('Uplaoding file: ', info);
  //   // if (info.file.status === 'done') {
  //   //   console.log('File uploaded successfully:', info.file);
  //   // } else if (info.file.status === 'error') {
  //   //   console.error('File upload failed:', info.file, " ERROR ", info.file.status, " INFO ", info);
  //   // }
  // };


  useEffect(() => {
    storeFinancingCompany(FINANCING_COMPANY.G4R);
    setFinanceCompany(true);
  }, []);

  return (
    <div className="commercial-proposal public-form public-form--go4rent-theme">
      <div className="form-wrapper">
        <h1 className="form-title">{t("com-title")}</h1>
        <h2 className="">{t("com-client")}</h2>

        <Form
          scrollToFirstError={true}
          className="form"
          form={form}
          layout="horizontal"
          onFinish={onFinish}>
          <Row className="client-container">
            <Col xs={24} sm={12} className="client-contacts">


              <CustomInput
                name="klientas"
                label={t("com-client")}
                rules={[{ required: true, message: t("com-client-error") }]} />

              <CustomInput
                name="email"
                label={t("com-email")}
                rules={[{ required: true, message: t("com-email-error") }, { type: "email", message: t("com-email-invalid") }]}
              />


              {/* <Form.Item name="email" 
              rules={[{ required: true, message: "Įveskite el. paštą" }, { type: "email", message: "Įveskite teisingą el. paštą" }]}>
                <Input placeholder="El. paštas *" className="gray-placeholder" />
              </Form.Item> */}

              <PhoneNumber
                form={form}
                label={t("com-phone")}
                name={["applicant", "phone"]}
                readOnly={false}
                showOnlyLTCode={true}
              />


              {/* <Form.Item name="phone" rules={[{ required: true, message: "Įveskite telefono numerį" }, { pattern: /^[0-9+() -]+$/, message: "Įveskite teisingą telefono numerį" }]}>
                <Input maxLength={15} placeholder="Tel. Nr. *" className="gray-placeholder" onKeyPress={(event) => { !/[0-9+() -]/.test(event.key) && event.preventDefault(); }} />
              </Form.Item> */}
            </Col>

            <Col xs={24} sm={12} className="client-date">

              <Form.Item
                name="data"
                rules={[{ required: true, message: t("com-date-error") }]}
                initialValue={moment()}

              >
                <DatePicker format="YYYY-MM-DD" className="gray-placeholder date-picker" placeholder={t("com-date")} />
              </Form.Item>
            </Col>
          </Row>



          <div className="car-container">
            {financeCompany && <AssetInformationBlock formInstance={form} readOnly={!financeCompany} commercialProposal={true} />}
          </div>
          <Row className="car-container" gutter={[16, 16]}>
            {[
              { labelKey:"com-price",  name: "kaina", required: true, type: "input" },
              { labelKey: "com-sdk", name: "sdk", required: true, type: "input" },
              {
                labelKey: "com-new-used",
                name: "naujasNaudotas",
                required: true,
                type: "select",
                options: [
                  { key: "naujas", value: "naujas", title: t("com-new") },
                  { key: "naudotas", value: "naudotas", title: t("com-used") }
                ]
              },
              { labelKey: "com-mileage", name: "rida", required: true, type: "input" },
              { labelKey: "com-vin", name: "kebuloNr", required: true, type: "input" },
              {
                labelKey: "com-gearbox",
                name: "greiciuDeze",
                type: "select",
                options: [
                  { key: "mechanine", value: "mechanine", title: t("com-gear-manual") },
                  { key: "automatine", value: "automatine", title: t("com-gear-auto") },
                  { key: "pusiau-automatine", value: "pusiau-automatine", title: t("com-gear-semi-auto") }
                ]
              },
              {
                labelKey: "com-car-wheels",
                name: "varantiejiRatai",
                required: true,
                type: "select",
                options: [
                  { key: "priekiniai", value: "priekiniai", title: "Priekiniai" },
                  { key: "galiniai", value: "galiniai", title: "Galiniai" },
                  { key: "visi", value: "visi", title: "Visi" }
                ]
              },
              { labelKey: "com-engine-size", name: "variklioTuris", required: true, type: "input" },
              { labelKey: "com-car-number", name: "valstybinisNr", type: "input" }
            ].map(({ labelKey, name, required, type, options }) => (
              <Col xs={24} sm={12} key={name}>
                {type === "input" ? (
                  <CustomInput
                    name={name}
                    label={t(labelKey)}
                    rules={[{ required, message: t(`com-enter-${labelKey}`) }]}
                  />
                ) : type === "select" ? (
                  <Select
                    showSearch={false}
                    label={t(labelKey)}
                    name={[name]}
                    options={options}
                    rules={[{ required, message: t(`com-select-${labelKey}`) }]}
                    // rules={[{ required, message: `${t("com-select-")} ${label.toLowerCase()}` }]}
                    disabled={false}
                  />

                  // <CustomSelect
                  //   name={name}
                  //   label={label}
                  //   options={options}
                  //   rules={[{ required, message: `Pasirinkite ${label.toLowerCase()}` }]}
                  // />
                ) : null}
              </Col>
            ))}

            <Col span={24} className="car-container-papildoma-info">
              <CustomTextArea
                name="papildomaInformacija"
                label={t("com-additional-info-label")}
                rules={[{ required: true, message: t("com-enter-additional-info") }]}
              />
            </Col>
          </Row>
          <div className="pardavejo-rekvizitai-container">
            <h2>{t("com-seller-info")}</h2>
            <button type="button" className="button-how-to-take-img" onClick={toggleSearchModal}>
              {t("com-find-company-in-rekvizitai")}
            </button>

            <Row>
              {[
                { labelKey: "com-company-code", name: "imonesKodas", required: true },
                { labelKey: "com-company-name", name: "imonesPavadinimas", required: true },
                { labelKey: "com-email-company", name: "elPastas", required: true },
                { labelKey: "com-phone-company", name: "telNr", required: true },
                { labelKey: "com-address", name: "kontaktinisAdresas" },
                { labelKey: "com-account", name: "atsiskaitomojiSaskaita", required: true }
              ].map(({ labelKey, name, required }) => (
                <Col xs={24} sm={12} key={name}>
                  <CustomInput
                    name={name}
                    label={t(labelKey)}
                    rules={[{ required, message: t(`com-enter-${labelKey}`) }]}
                  />
                </Col>
              ))}
            </Row>
          </div>


          <div>
            <h2>{t("com-upload-photos")}</h2>
            <div>
              <div>{t("com-photo-requirements")}</div>
              <ul>
                <li>{t("com-photo-requirement-1")}</li>
                <li>{t("com-photo-requirement-2")}</li>
                <li>{t("com-photo-requirement-3")}</li>
                <li>{t("com-photo-requirement-4")}</li>
                <li>{t("com-photo-requirement-5")}</li>
              </ul>
              <div>
                <button type="button" className="button-how-to-take-img" onClick={toggleModal}>
                  {t("com-how-to-photograph")}
                </button>
              </div>
            </div>
            <Form.Item
              valuePropName="fileList"
              getValueFromEvent={(e) => e?.fileList} // Ensure correct extraction of fileList
              name="images"
              rules={[
                {
                  required: true,
                  message: t("com-upload-error"), // Custom validation message
                },
                // {
                //   validator: (_, value) =>
                //     value && value.fileList && value.fileList.length > 0
                //       ? Promise.resolve()
                //       : Promise.reject(new Error('Prašome įkelti bent vieną nuotrauką!')),
                // },
              ]}
            >
              <Upload
                fileList={fileList}
                name="file" listType="picture" multiple
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleUploadChange}
                beforeUpload={handleBeforeUpload}  // Handle file upload manually
                showUploadList={{
                  showRemoveIcon: true,
                  showPreviewIcon: false,  // Disable preview while uploading
                }}>
                <div className="upload-button-container">
                  <Button type="button" className="upload-button" icon={<UploadOutlined />}>{t("com-upload-photos-button")} *</Button>
                </div>
              </Upload>
            </Form.Item>
          </div>




          <div className="submit-button-container">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="ant-btn ant-btn-default ant-btn-lg button button--primary button--large button--theme-go4rent">
                {loading ? <div className="spinner"></div> : t("com-submit")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={toggleModal}
        headerImageSrc={headerImageSrc}
        headerText={headerText}
        gridItems={gridItems} />
      <StatusModal
        isOpen={isStatusModalOpen}
        onClose={toggleStatusModal}
        type={statusModal}
        message={statusModalMessage} />
      <CompanySearchModal
        isOpen={isCompanySearchModalOpen}
        onClose={toggleSearchModal}
        onSelect={onSearchModalSelect}

      />
    </div>

  );
};

export default CommercialProposalForm;
