import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import leasingRequest from "pages/leasingRequest";
import LegacyBrowserMessage from "components/shared/legacyBrowserMessage";
import PublicRoute from "components/routes/publicRoute";
import "assets/scss/styles.scss";
import NotFoundPage from "pages/NotFoundPage";
import CommercialProposal from "pages/commercialProposal";


export const AuthContext = React.createContext();

const App = () => {
  const [state, dispatch] = React.useReducer((s) => s, {});

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push ({
      'event' : 'customPageview',
      'locality' : 'lt',
      'business' : 'go4rent',
    });
  });

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Router>
        <LegacyBrowserMessage />
        <Switch>
          <PublicRoute path="/leasingRequest/:applicationToken" component={leasingRequest} formType="leasing-request" />
          <PublicRoute
            path="/partner/prefill/:originatorPrivateToken"
            component={leasingRequest}
            formType="leasing-request"
          />
          <PublicRoute path="/partner/:originatorToken" component={leasingRequest} formType="leasing-request" />
          <PublicRoute path="/leasingRequest" exact component={leasingRequest} formType="leasing-request" />
          <PublicRoute path="/commercialProposal" exact component={CommercialProposal} formType="leasing-request"/>
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
