import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./config/i18n";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import { UserProvider } from "./contexts/userContext";
import TagManager from "react-gtm-module";
import config from "config/config";

TagManager.initialize({gtmId: config.gtmId});

ReactDOM.render(
  <UserProvider>
    <Suspense fallback="">
      <App />
    </Suspense>
  </UserProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
