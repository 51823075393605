import React, { useState } from "react";
import axios from "axios";
import { parseString } from "xml2js";
import settings from "config/config";
import { useTranslation } from "react-i18next";

const CompanySearchModal = ({ isOpen, onClose, onSelect }) => {
    const { t } = useTranslation();
  const [searchCode, setSearchCode] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gettingCompanyDetails, setGettingCompanyDetails] = useState(false);
  const [error, setError] = useState(null);
  const API_HOST = settings.backendApiUrl;


  const fetchSearchResults = async () => {
    if (!searchCode) {
      setError(t("com-companies-not-found"));
      return;
    }
    setLoading(true);
    setError(null);
    setData([]);
    try {
      const response = await axios.get(
        `${API_HOST}/document-generator/company-search?search=${searchCode}`
      );
      parseString(response.data, (err, result) => {
        if (err) {
          setError(t("com-company-not-found"));
        } else {

          if (result.data.status != "success"){
            setError(t("com-companies-search-error"));
          } else {
            const companies = result.data.companies?.[0]?.company || []; // Ensure it's an array


            const formattedData = companies.map(company => {
              return ({
                company_code: company.code?.[0] || "",
                company_name: company.title?.[0] || "",
              })
            });
            // console.log("FOUND COMPANIES ", formattedData);
            setData(formattedData);

          }

   
        }
      });
    } catch (err) {
      setError(t("com-companies-not-found"));
    }

    setSearchCode(null);
    setLoading(false);
  };


  const fetchCompanyData = async (companyCode) => {
    // console.log("Dar 1 ", companyCode);
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_HOST}/document-generator/company-details?code=${companyCode}`
      );
      return new Promise((resolve, reject) => {
        parseString(response.data, (err, result) => {
          if (err) {     setLoading(false); reject(err); }
          else {

            const companies = result.data.companies || {};
            const formattedData = companies.map(company => {
              const mobilePhone = company.company?.[0].mobile?.[0];
              const phone = company.company?.[0].phone?.[0];
              let telephone = "";
              if (phone) {
                telephone = phone;
              } else {
                if (mobilePhone) {
                  telephone = mobilePhone;
                }
              }
              return ({
                company_code: company.company?.[0].code?.[0] || "",
                company_name: company.company?.[0].title?.[0] || "",
                email: company.company?.[0].email?.[0] || "",
                telephone: telephone,
                address: company.company?.[0].address?.[0] || "",
              })
            });
            setLoading(false);
            resolve(formattedData[0]);

          }
        });
      });
    } catch (err) {
      // setError("Įmonių nerasta - prašome patikslinti paiešką");
      // console.log("Could not get company details", err);
    }

  };

  const handleRowClick = async (company) => {

    // console.log("La 1 ", company);

    const companyCode = company.company_code;

    const companyDetails = await fetchCompanyData(companyCode);
    onSelect(companyDetails);

  };

  return (
    isOpen && (
      <div className="company-modal">
        <div className="company-modal-content">
          <h2>{t("com-search-company")}</h2>
          <div className="company-search-container">
            <input
              type="text"
              value={searchCode}
              onChange={(e) => setSearchCode(e.target.value)}
              placeholder={t("com-enter-search-criteria")}
              className="company-input"
            />
            <button 
              onClick={fetchSearchResults} 
              disabled={loading} 
              className="ant-btn ant-btn-default ant-btn-lg button button--primary button--large button--theme-go4rent"
            >
              {t("com-search")}
            </button>
          </div>
          {loading && <p className="company-loading">{t("com-wait")}</p>}
          {error && <p className="company-error">{error}</p>}
          {data.length > 0 && !loading && !error && (
            <div className="company-table-container">
              <div className="title-pasirinkite-imone">{t("com-select-company")}</div>
              <table className="company-table">
                <thead>
                  <tr>
                    <th>{t("com-company-code-search")}</th>
                    <th>{t("com-company-name-search")}</th>
                    {/* <th>{t("com-email")}</th>
                    <th>{t("com-telephone")}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.map((company, index) => (
                    <tr key={index} className="company-table-row" onClick={() => handleRowClick(company)}>
                      <td>{company.company_code}</td>
                      <td>{company.company_name}</td>
                      {/* <td>{company.email}</td>
                      <td>{company.telephone}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!loading &&  <button onClick={onClose} className="company-close-button ant-btn ant-btn-default ant-btn-lg button button--primary button--large button--theme-go4rent">{t("com-close")}</button>}
        </div>
      </div>
    )
  );
  
};

export default CompanySearchModal;
