import React from "react";
import { XCircle, CheckCircle } from "lucide-react";


const StatusModal = ({ isOpen, onClose, type, message }) => {
  if (!isOpen) return null;

  const isSuccess = (type === "success");

  return (
    <div className="status-modal-overlay">
      <div className="status-modal-content">
        <div className="status-modal-header">
          {type === "success" ? (
            <CheckCircle className="status-modal-icon status-modal-success-icon" />
          ) : (
            <XCircle className="status-modal-icon status-modal-error-icon" />
          )}
        </div>
        <p className="status-modal-message">{message}</p>
        <button className="ant-btn ant-btn-default ant-btn-lg button button--primary button--large button--theme-go4rent" onClick={()=>{onClose(isSuccess)}}>
          Uždaryti
        </button>
      </div>
    </div>
  );
};

export default StatusModal;
