import React from "react";

const Modal = ({ isOpen, onClose, headerImageSrc, headerText, gridItems }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Close Button */}
        <button className="close-button" onClick={onClose}>×</button>
        
        {/* Header */}
        <div className="modal-header">
          {/* <img src={headerImageSrc} alt="Header" className="header-image" /> */}
          <div className="header-text">
          {/* <div className="header-text-log">go4rent</div> */}
          <div className="header-text-description">{headerText}</div>
          </div>
        </div>
        
        {/* Main Content Grid (Scrollable) */}
        <div className="modal-grid-scrollable">
          <div className="modal-grid">
            {gridItems.map((item, index) => (
              <div className="grid-item" key={index}>
                <img src={item.image} alt="Item" className="grid-image" />
                <p className="grid-text">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
